import React, { useState } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

const MAX_SIZE = 5000000;

const FormFileButton = ({
  label,
  filesState,
  setFilesState,
  name,
  formSubmitted,
}) => {
  const [error, setError] = useState("");

  const { t } = useTranslation();

  const handleFileChange = (e) => {
    setError("");
    setFilesState([]);
    if (e.currentTarget.files[0].size > MAX_SIZE) {
      setError(t("TR_FORM_FILE_SIZE"));
    } else {
      setFilesState(e.currentTarget.files);
    }
  };

  return (
    <div className='sm:flex-row flex flex-col items-center gap-2'>
      <label
        htmlFor={name}
        className={`${
          formSubmitted
            ? " hover:cursor-default"
            : "hover:cursor-pointer hover:bg-neutral-700"
        }  ${
          error ? "bg-red-600 animate-pulse-op" : "bg-neutral-400"
        } h-8 px-6 py-2 text-xs font-bold text-white duration-300 ease-in-out rounded`}
      >
        {label}
        {filesState.length > 0 && ` (${filesState.length})`}
        <input
          id={name}
          type='file'
          name={name}
          disabled={formSubmitted}
          accept='application/pdf, application/msword, image/*, video/*'
          className='hidden'
          onChange={handleFileChange}
        />
      </label>
      <div className='right-3 text-xs text-red-600'>{error}</div>
    </div>
  );
};

export default FormFileButton;
