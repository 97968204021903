import React from "react";

const ContactFormHeader = ({ title, subtitle }) => {
  return (
    <div>
      <h2 className='text-xl font-bold'>{title}</h2>
      <h3 className='pt-6'>{subtitle}</h3>
    </div>
  );
};

export default ContactFormHeader;
