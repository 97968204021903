import React from "react";

import Skype from "../../Icons/Skype";
import Phone from "../../Icons/Phone";

const ContactAddress = ({ header, address }) => {
  return (
    <div className='pb-7'>
      <article className='flex flex-col gap-4 pb-3'>
        <h1 className='lg:text-3xl text-2xl font-bold uppercase'>
          {header.pageTitle}
        </h1>
        <h2 className='md:text-lg text-sm font-bold'>{header.subtitle}</h2>
      </article>
      <article className='md:text-sm flex flex-col gap-3 text-sm'>
        <div>
          <p>{address.addressLine1}</p>
          <p>{address.addressLine2}</p>
        </div>
        <p>{address.openHours}</p>
        <div className='flex items-center gap-2 pl-2'>
          <Phone />
          <p>{address.phone}</p>
        </div>
        <div className='flex items-center gap-2 pl-2'>
          <Skype />
          <p>{address.skype}</p>
        </div>
      </article>
    </div>
  );
};

export default ContactAddress;
