import React from "react";

const ColorOnClickButton = ({ label, onClickFn, fnValue, color }) => {
  const bgColor = color ? `bg-${color}` : "bg-cadt";
  const hoverBgColor = color
    ? `hover:bg-${color}-hover`
    : "hover:bg-cadt-hover";

  return (
    <div className='pt-6'>
      <button
        onClick={() => onClickFn(fnValue)}
        className={`${bgColor} ${hoverBgColor} rounded-button`}
      >
        {label}
      </button>
    </div>
  );
};

export default ColorOnClickButton;
