import React, { useState } from "react";
import { Formik, Form } from "formik";

import FormFilesButton from "../../Global/FormComponents/FormFilesButton";
import FormCheckboxPrivacy from "../../Global/FormComponents/FormCheckboxPrivacy";
import FormInput from "../../Global/FormComponents/FormInput";
import FormTextArea from "../../Global/FormComponents/FormTextArea";
import FormSubmitButton from "./../../Global/FormComponents/FormSubmitButton";

import {
  initialValues,
  useValidationSchema,
} from "../../Hooks/useValidationSchemaContact";

const ContactFormBody = ({ formContent, setThankYou }) => {
  const { topic, name, company, email, message, privacy, files, submit } =
    formContent;

  const [filesState, setFilesState] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const validationSchema = useValidationSchema();

  const encode = (data) => {
    const formData = new FormData();
    for (const key of Object.keys(data)) {
      formData.append(key, data[key]);
    }
    return formData;
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        setFormSubmitted(true);
        values.files = filesState[0];
        fetch("/", {
          body: encode({ "form-name": "contact", ...values }),
          method: "POST",
        })
          .then(() => {
            setThankYou(values.name);
          })
          .catch((error) => {
            console.log("error", error);
          });
      }}
    >
      <Form
        className='flex flex-col gap-2 pt-4 mb-4'
        name='contact'
        method='POST'
        data-netlify='true'
        data-netlify-honeypot='bot-field'
        encType='multipart/form-data'
      >
        <input type='hidden' name='form-name' value='contact' />
        <input type='hidden' name='subject' value='' />
        <input type='hidden' name='bot-field' />
        <FormInput
          required={topic.required}
          name={topic.id}
          type={topic.type}
          label={topic.label}
        />
        <FormInput
          required={name.required}
          name={name.id}
          type={name.type}
          label={name.label}
        />
        <FormInput
          required={company.required}
          name={company.id}
          type={company.type}
          label={company.label}
        />
        <FormInput
          required={email.required}
          name={email.id}
          type={email.type}
          label={email.label}
        />

        <FormTextArea placeholder={message.label} name={message.id} />

        <FormCheckboxPrivacy name={privacy.id} label={privacy.label} />

        <div className='flex justify-between'>
          <FormFilesButton
            label={files.label}
            name={files.id}
            filesState={filesState}
            setFilesState={setFilesState}
            formSubmitted={formSubmitted}
          />
          <FormSubmitButton info={submit} formSubmitted={formSubmitted} />
        </div>
      </Form>
    </Formik>
  );
};

export default ContactFormBody;
