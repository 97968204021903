import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import ContactForm from "../components/Contact/ContactForm/ContactForm";
import ContactInfo from "../components/Contact/ContactInfo/ContactInfo";

import Instagram from "../components/Icons/Instagram";
import Facebook from "../components/Icons/Facebook";
import Linkedin from "../components/Icons/Linkedin";
import Twitter from "../components/Icons/Twitter";
import Layout from "../components/Layout";

const Contact = (props) => {
  const { t } = useTranslation();

  const data = {
    header: {
      pageTitle: t("TR_INFO_CONTACT_US"),
      subtitle: t("TR_INFO_YOU_CAN_FIND_US"),
    },
    address: {
      addressLine1: t("TR_INFO_COMPANY_ADDRESS1"),
      addressLine2: t("TR_INFO_COMPANY_ADDRESS2"),
      openHours: t("TR_INFO_OPEN_HOURS"),
      phone: "+34 935 891 567",
      skype: "cadt.com",
    },
    social: {
      title: t("TR_INFO_FOLLOW_US_SOCIAL_MEDIA"),
      icons: [
        { icon: <Instagram />, link: "https://www.instagram.com" },
        { icon: <Linkedin />, link: "https://www.linkedin.com" },
        { icon: <Twitter />, link: "https://www.twitter.com" },
        { icon: <Facebook />, link: "https://www.facebook.com" },
      ],
    },
    form: {
      title: t("TR_CONTACT_FORM_TITLE"),
      subtitle: t("TR_CONTACT_FORM_SUBTITLE"),
      content: {
        topic: {
          id: "topic",
          label: t("TR_FORM_SUBJECT"),
          required: true,
          type: "text",
        },
        name: {
          id: "name",
          label: t("TR_FORM_NAME"),
          required: true,
          type: "text",
        },
        company: {
          id: "company",
          label: t("TR_FORM_COMPANY"),
          required: false,
          type: "text",
        },
        email: {
          id: "email",
          label: t("TR_FORM_EMAIL"),
          required: true,
          type: "email",
        },
        message: {
          id: "message",
          label: t("TR_FORM_MESSAGE"),
        },
        privacy: {
          id: "privacy",
          label: {
            accept: t("TR_FORM_PRIVACY_ACCEPT"),
            privacy: t("TR_FORM_PRIVACY"),
          },
          required: true,
        },
        files: {
          id: "files",
          label: t("TR_FORM_FILES"),
          required: false,
        },
        submit: { id: "send", label: t("TR_FORM_SEND") },
      },
    },
  };

  return (
    <div className='bg-neutral-50'>
      <Layout>
        {data && (
          <div className='sm:grid sm:grid-cols-2 gap-3 pt-[76px] md:pt-10'>
            <ContactInfo
              header={data.header}
              address={data.address}
              social={data.social}
              photo={props.data.contactPhoto.childImageSharp.gatsbyImageData}
            />
            <ContactForm form={data.form} />
          </div>
        )}
      </Layout>
    </div>
  );
};

export default Contact;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    contactPhoto: file(relativePath: { regex: "/contact-page/i" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`;

export const Head = ({ data }) => {
  const title = JSON.parse(data.locales.edges[0].node.data).TR_MENU_CONTACT;

  return (
    <>
      <title>{title} | CADT</title>
    </>
  );
};
