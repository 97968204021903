import React from "react";

import ContactInfoSocial from "./ContactInfoSocial";
import ContactInfoAddress from "./ContactInfoAddress";
import ContactInfoPhoto from "./ContactInfoPhoto";

const ContactInfo = ({ photo, header, address, social }) => {
  return (
    <section className='flex flex-col items-center pb-10'>
      <ContactInfoPhoto photo={photo} />
      <div className='w-max pt-2'>
        <ContactInfoAddress header={header} address={address} />
        <ContactInfoSocial social={social} />
      </div>
    </section>
  );
};

export default ContactInfo;
