import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const ContactPhoto = ({ photo }) => {
  return (
    <>
      <div className='sm:block h-60 lg:h-80 relative hidden w-full bg-center bg-no-repeat bg-cover'>
        {/* Vertical gradient */}
        <div
          className='absolute top-0 z-10 w-full h-full'
          style={{
            backgroundImage:
              "linear-gradient(rgba(248, 250, 252, 0) 0%, rgba(248, 250, 252, 0) 75%, rgba(248, 250, 252, 1) 95%)",
          }}
        ></div>

        {/* Horizontal gradient */}
        <div
          className='absolute top-0 z-10 w-full h-full'
          style={{
            backgroundImage:
              "linear-gradient(90deg, rgba(248, 250, 252, 0) 0%, rgba(248, 250, 252, 0) 75%, rgba(248, 250, 252, 1) 95%)",
          }}
        ></div>
        <GatsbyImage
          image={photo}
          alt='CADT contact'
          className='w-auto h-full'
        />
      </div>

      {/* Mobile version */}
      <div className='sm:hidden h-60 lg:h-80 relative w-full bg-center bg-no-repeat bg-cover'>
        <div
          className='absolute top-0 z-10 w-full h-full'
          style={{
            backgroundImage:
              "linear-gradient(rgba(248, 250, 252, 0) 0%, rgba(248, 250, 252, 0) 75%, rgba(248, 250, 252, 1) 95%)",
          }}
        ></div>
        <GatsbyImage
          image={photo}
          alt='CADT contact'
          className='w-auto h-full'
        />
      </div>
    </>
  );
};

export default ContactPhoto;
