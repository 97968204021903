import React, { useState } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

import ContactFormHeader from "./ContactFormHeader";
import ContactFormBody from "../ContactForm/ContactFormBody";
import ColorOnClickButton from "../../Global/Buttons/ColorOnClickButton";

const ContactForm = ({ form }) => {
  const { t } = useTranslation();

  const [thankYou, setThankYou] = useState(null);

  return (
    <section className='sm:ml-0 xl:mr-20 2xl:mr-40 py-10 ml-10 mr-10'>
      {thankYou ? (
        <>
          <ContactFormHeader
            title={t("TR_THANK_YOU_TITLE", { extra: thankYou })}
            subtitle={t("TR_THANK_YOU_DESCRIPTION")}
          />
          <ColorOnClickButton
            onClickFn={setThankYou}
            fnValue={null}
            label={t("TR_THANK_YOU_BUTTON")}
          />
        </>
      ) : (
        <>
          <ContactFormHeader title={form.title} subtitle={form.subtitle} />
          <ContactFormBody
            formContent={form.content}
            setThankYou={setThankYou}
          />
        </>
      )}
    </section>
  );
};
export default ContactForm;
