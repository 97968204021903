import React from "react";

function Skype() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 96 96'
      className='w-8 h-8'
    >
      <path
        style={{
          stroke: "none",
          fillRule: "nonzero",
          fill: "rgb(96.078432%,81.960785%,18.82353%)",
          fillOpacity: "1",
        }}
        d='M 48 8 C 25.910156 8 8 25.910156 8 48 C 8 70.089844 25.910156 88 48 88 C 70.089844 88 88 70.089844 88 48 C 88 25.910156 70.089844 8 48 8 Z M 48 8 '
      />
      <path
        style={{
          stroke: "none",
          fillRule: "nonzero",
          fill: "rgb(96.078432%,81.960785%,18.82353%)",
          fillOpacity: "1",
        }}
        d='M 67 44 C 54.296875 44 44 54.296875 44 67 C 44 79.703125 54.296875 90 67 90 C 79.703125 90 90 79.703125 90 67 C 90 54.296875 79.703125 44 67 44 Z M 29 6 C 16.296875 6 6 16.296875 6 29 C 6 41.703125 16.296875 52 29 52 C 41.703125 52 52 41.703125 52 29 C 52 16.296875 41.703125 6 29 6 Z M 29 6 '
      />
      <path
        style={{
          stroke: "none",
          fillRule: "nonzero",
          fill: "rgb(100%,100%,100%)",
          fillOpacity: "1",
        }}
        d='M 49.203125 72 C 36 72 30 65.398438 30 60.398438 C 30 57.796875 31.796875 56 34.398438 56 C 40 56 38.601562 64.203125 49.203125 64.203125 C 54.601562 64.203125 57.601562 61.203125 57.601562 58.203125 C 57.601562 56.398438 56.796875 54.398438 53.203125 53.398438 L 41.398438 50.398438 C 32 48 30.203125 42.796875 30.203125 37.796875 C 30.203125 27.601562 39.601562 24 48.398438 24 C 56.601562 24 66 28.398438 66 34.398438 C 66 37 64 38.601562 61.398438 38.601562 C 56.601562 38.601562 57.398438 31.796875 47.796875 31.796875 C 43 31.796875 40.203125 34.003906 40.203125 37.203125 C 40.203125 40.398438 43.996094 41.398438 47.398438 42.203125 L 56.203125 44.203125 C 65.796875 46.398438 68 52 68 57.398438 C 67.796875 65.796875 61.796875 72 49.203125 72 Z M 49.203125 72 '
      />
    </svg>
  );
}

export default Skype;
