import React from "react";
import ContactInfoSocialIcons from "./ContactInfoSocialIcons";

const ContactSocial = ({ social }) => {
  return (
    <div className='flex flex-col gap-3'>
      <div className='lg:text-sm'>{social && social.title}</div>
      <ContactInfoSocialIcons icons={social.icons} />
    </div>
  );
};

export default ContactSocial;
