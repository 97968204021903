import React from "react";

const Phone = () => {
  return (
    <svg
      className='w-8 h-8'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M24.825 26.975C22.625 26.975 20.5125 26.5875 18.4875 25.8125C16.4625 25.0375 14.6708 23.9625 13.1125 22.5875C11.5542 21.2125 10.3125 19.5875 9.3875 17.7125C8.4625 15.8375 8 13.8 8 11.6C8 10.8667 8.20417 10.25 8.6125 9.75C9.02083 9.25 9.6 9 10.35 9H12.675C13.125 9 13.475 9.12917 13.725 9.3875C13.975 9.64583 14.15 10.0167 14.25 10.5C14.35 11 14.4625 11.5167 14.5875 12.05C14.7125 12.5833 14.8583 13.0583 15.025 13.475C15.175 13.825 15.2208 14.1542 15.1625 14.4625C15.1042 14.7708 14.9333 15.0667 14.65 15.35L12.7 17.25C13.5 18.4167 14.6125 19.5458 16.0375 20.6375C17.4625 21.7292 18.85 22.475 20.2 22.875L21.35 20.55C21.5 20.25 21.6917 20.0458 21.925 19.9375C22.1583 19.8292 22.4833 19.7917 22.9 19.825C23.3333 19.8583 23.7625 19.875 24.1875 19.875C24.6125 19.875 25.0917 19.8583 25.625 19.825C26.125 19.7917 26.5458 19.925 26.8875 20.225C27.2292 20.525 27.4583 21.0083 27.575 21.675L27.975 23.925C27.975 23.925 27.9917 24.0667 28.025 24.35C28.025 25.05 27.7375 25.6625 27.1625 26.1875C26.5875 26.7125 25.8083 26.975 24.825 26.975Z'
        fill='#F5D130'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18 33C26.2843 33 33 26.2843 33 18C33 9.71573 26.2843 3 18 3C9.71573 3 3 9.71573 3 18C3 26.2843 9.71573 33 18 33ZM18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z'
        fill='#F5D130'
      />
    </svg>
  );
};

export default Phone;
