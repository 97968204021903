import React from "react";

const ContactSocial = ({ icons }) => {
  return (
    <div className='flex gap-3'>
      {icons &&
        icons.map(({ icon, link }, i) => (
          <a
            key={i}
            href={link}
            target='_blank'
            rel='noreferrer'
            className='hover:cursor-pointer bg-cadt fill-neutral-50 hover:bg-cadt-dark w-8 h-8 p-2 duration-200 ease-in-out rounded-full'
          >
            {icon}
          </a>
        ))}
    </div>
  );
};

export default ContactSocial;
